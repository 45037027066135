<template>
  <div class="ProfessionalTeam">
    <div class="banner">
      <!-- <img class="bannerPos" src="@/assets/servicebanner.png" alt="" /> -->
      <div class="text">
        <div>
          <span>为客户提供全球跨境投资及争议解决</span>
          <span>一站式法律服务</span>
        </div>
        <p><img src="@/assets/bannertel.png" alt="" /> 400-662-2729</p>
      </div>
    </div>
    <div class="contain" id="htmlContent">
      <div class="title"><i></i>专业团队<span>Professional team</span></div>
      <div class="box">
        <div class="boxTop">
          <!-- <ul>
            <li
              v-for="(x, i) in lawyerList"
              :key="i"
              @click="show = x.id"
              :class="{ act: show == x.id }"
            >
              <img :src="x.photo" alt="" />
            </li>
          </ul> -->
          <swiper :options="swiperOptions" ref="swiper">
            <swiper-slide
              v-for="(x, i) in lawyerList"
              :key="i"
              @click="show = x.id"
              :class="{ act: show == x.id }"
            >
              <img :src="x.photo" alt="" />
            </swiper-slide>
            <!-- 分页器 -->
            <!-- <div class="swiper-pagination" slot="pagination"></div> -->
            <!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
            <!-- <div class="swiper-button-prev" slot="button-prev"></div> -->
            <!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
            <!-- <div class="swiper-button-next" slot="button-next"></div> -->
          </swiper>
        </div>
        <div class="boxBot">
          <div
            v-for="(x, i) in lawyerList"
            :key="i"
            class="props"
            v-show="show == x.id"
          >
            <div class="propsTop">
              <div class="propsTopLeft">
                <h4>
                  {{ x.name }} {{ x.englishName }} <span>{{ x.type }}</span>
                </h4>
                <h5>职务头衔</h5>
                <p class="position">
                  {{ x.officeName }} |
                  <template v-for="item in x.jobList">
                    {{ item }}
                  </template>
                </p>
              </div>
              <div class="propsTopRight">
                <img :src="x.photo" alt="" />
              </div>
            </div>
            <div class="propsBot">
              <h4>管理经验</h4>
              <p>
                {{ x.managerialExperience }}
              </p>
              <h4 class="h4Bot">业务领域</h4>
              <p>
                {{ x.businessAreas }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// 导入swiper的样式
import "swiper/css/swiper.css";
export default {
  props: ["id"],
  data() {
    return {
      show: 0,
      lawyerList: [], //律师
      // 定义swiper的配置选项
      swiperOptions: {
        // 指定分页器
        pagination: {
          //指定分页器的容器
          el: ".swiper-pagination",
          //点击分页器的指示点分页器会控制Swiper切换
          clickable: true,
        },
        //initialSlide: 2,
        // 配置衔接滑动
        loop: true,
        // 配置自动播放
        // autoplay:true
        autoplay: {
          //自动播放
          autoplay: true,
          //设置间隔时间
          delay: 3000,
          // 用户操作swiper之后，是否禁止autoplay
          disableOnInteraction: false,
        },
        slidesPerView: 4, //设置能够同时显示的数量（可设置 auto）
        // 箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          slideChangeTransitionStart: () => {
            if (!this.swiper) return;
            var index = this.swiper.realIndex;
            var arrayIndex = index == this.lawyerList.length ? 0 : index;
            this.show = this.lawyerList[arrayIndex].id;
            console.log(arrayIndex, 666);
          },
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.swiper.$swiper;
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  created() {
    this.getLawyerList();
    this.$store.state.navChange = 3;
  },
  mounted() {
    setTimeout(function () {
      let htmlContent = document.getElementById("htmlContent");
      htmlContent.scrollIntoView({ behavior: "smooth" });
    }, 100);
  },
  methods: {
    //获得律师
    getLawyerList() {
      this.axios({
        type: "get",
        // url: this.Url.apiUrl + "Lawyer/GetTopLawyer",
        url: this.Url.apiUrl + "Lawyer/GetLawyer",
        params: {
          n: 5,
        },
      }).then((res) => {
        // if (res.data && res.data.length > 1) {
        //   var lastData = res.data[res.data.length - 1];
        //   res.data.splice(res.data.length - 1, 1);
        //   res.data.splice(0, 0, lastData);
        //   console.log(res.data);
        // }
        this.lawyerList = res.data;
        this.show = this.$route.params.id || this.lawyerList[3].id;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/ProfessionalTeam.scss";
</style>
<style scoped>
.swiper-wrapper {
  display: flex;
}
.swiper-slide {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide img {
  width: 55%;
  height: 80%;
  border-radius: 50%;
  border: 5px solid transparent;
}
.swiper-slide-active img {
  border: 5px solid #fcba53;
}

@media screen and (max-width: 700px) {
  .swiper-slide {
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    width: 75%;
    height: 55%;
    border-radius: 50%;
    border: 5px solid transparent;
  }
  .swiper-slide-active img {
    border: 5px solid #fcba53;
  }
}
</style>